import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import axios from "@/utils/request";
import apiUrls from "@/api/index.js";
import * as echarts from "echarts";
import AntD from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "@/assets/css/public.less";

import Quill from "quill";
import "quill/dist/quill.snow.css";

import VueCropper from "vue-cropper";

Vue.use(VueCropper);
Vue.use(AntD);
// Vue.use(Quill);

Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.prototype.$axios = axios;
Vue.prototype.$apiUrls = apiUrls;
Vue.prototype.$eCharts = echarts;
Vue.prototype.$quill = Quill;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
