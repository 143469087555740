// 通用接口前缀
const renren_admin = "/renren-admin";
const apiUrls = {};

// 上传文件
apiUrls.uploadServer = `${renren_admin}/sys/oss/uploadServer`;

// 获取验证码
apiUrls.captcha = `${renren_admin}/captcha`;
// 登录
apiUrls.login = `${renren_admin}/login`;
// 退出登录
apiUrls.logout = `${renren_admin}/logout`;

// 课程信息表 - 列表
apiUrls.getCourseList = `${renren_admin}/study/courses/page`;
// 课程信息表 - 新增/修改/删除
apiUrls.optCourse = `${renren_admin}/study/courses`;
// 课程信息表 - 根据课程id获取该课程章节目录
apiUrls.getCourseChapterList = `${renren_admin}/study/courses/getCourseChapterList`;

// 课程大章 - 新增/修改/删除
apiUrls.optChapters = `${renren_admin}/study/chapters`;
// 课程小节 - 新增/修改/删除
apiUrls.optSubchapters = `${renren_admin}/study/subchapters`;

// 角色管理 - 列表（分页）
apiUrls.getRolePage = `${renren_admin}/sys/role/page`;
// 角色管理 - 列表（不分页）
apiUrls.getRoleList = `${renren_admin}/sys/role/list`;
// 角色管理 - 新增
apiUrls.roleSave = `${renren_admin}/sys/role/save`;
// 角色管理 - 详情
apiUrls.roleInfo = `${renren_admin}/sys/role`;
// 角色管理 - 编辑
apiUrls.roleEdit = `${renren_admin}/sys/role/update`;
// 角色管理 - 删除
apiUrls.roleDel = `${renren_admin}/sys/role/delete`;

// 用户管理 - 列表
apiUrls.getUserList = `${renren_admin}/sys/user/page`;
// 用户管理 - 新增/修改/删除
apiUrls.optUser = `${renren_admin}/sys/user`;
// 用户管理 - 用户关联课程(设置用户免费观看课程)
apiUrls.userCorrelationFreeCourse = `${renren_admin}/sys/user/userCorrelationFreeCourse`;
// 用户管理 - 查询用户关联课程
apiUrls.getUserCorrelationCourseIds = `${renren_admin}/sys/user/getUserCorrelationCourseIds`;

// 轮播图管理 - 列表
apiUrls.getCarouselPage = `${renren_admin}/study/carouselchart/page`;
// 轮播图管理 - 新增/修改/删除
apiUrls.optCarousel = `${renren_admin}/study/carouselchart`;

// 订单管理 - 列表
apiUrls.getOrderPage = `${renren_admin}/study/orderinfo/page`;
// 订单管理 - 新增/修改/删除
apiUrls.optOrder = `${renren_admin}/study/orderinfo`;
// 订单管理 - 获取总金额
apiUrls.statTotalMoney = `${renren_admin}/study/orderinfo/statTotalMoney`;

// 分类管理 - 分页
apiUrls.getSubjectCategoryPage = `${renren_admin}/study/subjectcategory/page`;
// 分类管理 - 新增/修改/删除
apiUrls.optSubjectCategory = `${renren_admin}/study/subjectcategory`;
// 分类管理 - 列表
apiUrls.getSubjectCategoryList = `${renren_admin}/study/subjectcategory/list`;

// 课程课件信息 - 列表
apiUrls.getMaterialsPage = `${renren_admin}/study/lecturematerials/page`;
// 课程课件信息 - 新增/修改/删除
apiUrls.optMaterials = `${renren_admin}/study/lecturematerials`;
// 获取文件类型列表
apiUrls.getFileTypes = `${renren_admin}/study/lecturematerials/getFileTypes`;
// 章节和课件关联
apiUrls.contactInfo = `${renren_admin}/study/coursefileref/saveBatch`;
// 章节和课件删除关联
apiUrls.delContact = `${renren_admin}/study/coursefileref`;
// 根据课程id获取该课程所关联资源列表
apiUrls.getCourseFiles = `${renren_admin}/study/courses/getCourseFiles`;
// 根据课程id获取该课程所关联资源列表
apiUrls.getCoursesByFileId = `${renren_admin}/study/lecturematerials/getCoursesByFileId`;
// 排序更新
apiUrls.updateOrder = `${renren_admin}/study/courses/updateOrder`;

// 根据字典类型获取字典数据
apiUrls.getDictDataListByType = `${renren_admin}/sys/dict/data/getDictDataListByType`;

export default apiUrls;
