import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem("token") || "",
  },
  mutations: {
    updateToken(state, val) {
      state.token = val;
      sessionStorage.setItem("token", val);
    },
  },
});
