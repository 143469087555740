import axios from "axios";
import router from "@/router/index";
import { message } from "ant-design-vue";

const service = axios.create({
  baseURL: "",
  timeout: 5000 * 12 * 60, //60分钟
});

// 请求前附令牌
service.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      token: sessionStorage.getItem("token"), // 附令牌到请求头
    };
    console.log("config", config);
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截
service.interceptors.response.use(
  (response) => {
    console.log("response.status", response);
    if (response.data.code === 200 || response.data.code === 0) {
      return response.data;
    } else if (response.data.code === 405) {
      message.error(response.data.msg);
    } else if (response.data.code === 406) {
      message.error(response.data.msg);
    } else if (response.data.code === -1) {
      message.error(response.data.msg);
      return response.data;
    } else if (response.data.code === 404) {
      return Promise.reject(response.data);
    } else if (response.data.code === 500) {
      message.error(response.data.msg);
      return response.data;
    } else if (response.data.code === 401 || response.data.code === 403) {
      // token过期重定向到登录页面
      message.error("登录状态失效，请重新登录");
      router.replace({
        path: "/",
        query: {
          redirect: router.currentRoute.fullPath,
        },
      });
    } else {
      message.error(response.data.msg);
      return response.data;
    }
  },
  () => {
    message.error("请求超时！");
    return Promise.reject({
      code: 1030,
      message: "请求失败！",
    });
  }
);

export default service;
