import Router from "vue-router";
import Vue from "vue";
Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login"),
  },
  {
    path: "/platform",
    component: () => import("@/views/platform"),
    redirect: "/platform/courseManagement",
    children: [
      {
        path: "homepage",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/homepage"),
      },
      {
        path: "courseManagement",
        meta: {
          title: "课程管理",
        },
        component: () => import("@/views/courseManagement"),
      },
      {
        path: "subjectCategoryManagement",
        meta: {
          title: "分类管理",
        },
        component: () => import("@/views/subjectCategoryManagement"),
      },
      {
        path: "videoManagement",
        meta: {
          title: "资源管理",
        },
        component: () => import("@/views/videoManagement"),
      },
      {
        path: "carouselManagement",
        meta: {
          title: "轮播图管理",
        },
        component: () => import("@/views/carouselManagement"),
      },
      {
        path: "orderManagement",
        meta: {
          title: "订单管理",
        },
        component: () => import("@/views/orderManagement"),
      },
      {
        path: "userManagement",
        meta: {
          title: "用户管理",
        },
        component: () => import("@/views/userManagement"),
      },
      {
        path: "roleManagement",
        meta: {
          title: "角色管理",
        },
        component: () => import("@/views/roleManagement"),
      },
    ],
  },
];

const createRoute = () =>
  new Router({
    routes,
    mode: "history",
  });

export default createRoute();
